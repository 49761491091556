import React, { useState, useEffect } from "react";
import MobileWarning from "~/components/MobileWarning";

export interface IWithMobileCheck {
  children: React.ReactNode;
}

const WithMobileCheck = (props: IWithMobileCheck) => {
  const { children } = props;
  const [isMobile, setIsMobile] = useState(false);

  const checkWindowSize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileWarning /> : children}
    </>
  );
};

export default WithMobileCheck;
