import { AppType } from "next/app";
import "../css/globals.css";
import "../css/landing.css";
import "../css/main.css";
import WithMobileCheck from "./WithMobileCheck";
import Layout from "../layouts/root";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const AIMarketplace: AppType<{ session?: Session | null }> = ({ Component, pageProps: { session, ...pageProps } }) => {

    return (
        <QueryClientProvider client={queryClient}>
            <SessionProvider session={session}>
                <WithMobileCheck>
                    <Layout>
                        <Head>
                            {/* Favicon */}
                            <link rel="icon" href="/automation_station_favicon.png" />

                            {/* Webclip (Apple Touch Icon) */}
                            <link
                                rel="apple-touch-icon"
                                sizes="256x256"
                                href="/automation_station_webclip.png"
                            />
                            <link rel="shortcut icon" href="https://morningside.ai/images/Logo/mark.png" />
                        </Head>
                        <Component {...pageProps} />
                    </Layout>
                </WithMobileCheck>
            </SessionProvider>
        </QueryClientProvider>
    );

};

export default AIMarketplace;